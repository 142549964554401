var glCountryId = "CountryID";
var glPostCodeId = "PostCode";

Sys.Mvc.ValidatorRegistry.validators.dateofbirth = function (rule) {
    return function (value, context) {
        if (value.length < 10) {
            return true;
        }
        var minAge = rule.ValidationParameters.minage;
        var maxAge = rule.ValidationParameters.maxage;
        var maxageerrormessage = rule.ValidationParameters.maxageerrormessage;

        //var minDate = new Date();
        var minDate = GetCurrentSystemDate();
        var currentYear = minDate.getFullYear();
        currentYear = currentYear - maxAge;
        minDate.setFullYear(currentYear);

        //var maxDate = new Date();
        var maxDate = GetCurrentSystemDate();
        currentYear = maxDate.getFullYear();
        currentYear = currentYear - minAge;
        maxDate.setFullYear(currentYear);

        var dayIndex = value.indexOf('/');
        var monthIndex = value.lastIndexOf('/');
        var day;
        var month;
        var year;
        day = value.substr(0, 2);
        month = value.substr(dayIndex + 1, monthIndex - dayIndex - 1) - 1;
        year = value.substr(monthIndex + 1, 10 - monthIndex - 1);
        var thisdate = new Date(year, month, day)
        if (thisdate >= minDate && thisdate <= maxDate) {
            return true;
        }
        else {
            if (thisdate < minDate) {
                return maxageerrormessage;
            }
            return rule.ErrorMessage;
        }
    };
};

Sys.Mvc.ValidatorRegistry.validators.mustmatch = function (rule) {
    var propertyIdToMatch = rule.ValidationParameters.propertyidtomatch;
    var message = rule.ErrorMessage;
    var regexpattern = rule.ValidationParameters.regexpattern;

    return function (value, context) {
        var thisField = context.fieldContext.elements[0];
        var otherField = $get(propertyIdToMatch, thisField.form);
        var matchPattern = true;

        // If RegEx Pattern is specified, the rule will match values only for values matching pattern
        if (regexpattern != null && regexpattern != 'undefined' && regexpattern.length > 0) {
            var regExp = new RegExp(regexpattern);
            matchPattern = regExp.test(value);
        }

        // If value is blank will not compare as it should be handled by required validator 
        if (value != null && value != 'undefined' && value.length > 0 && matchPattern) {
            if (otherField.value != value) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return true;
        }
    };
};

Sys.Mvc.ValidatorRegistry.validators.datatypedate = function (rule) {
    return function (value, context) {
        if (!value || !value.length) {
            return true; // return valid if value not specified - leave that to the 'required' validator
        }
        var dayIndex = value.indexOf('/');
        var monthIndex = value.lastIndexOf('/');
        var day;
        var month;
        var year;
        day = value.substr(0, 2);
        month = value.substr(dayIndex + 1, monthIndex - dayIndex - 1) - 1;
        year = value.substr(monthIndex + 1, 4);
        var thisdate = new Date(year, month, day)
        if (thisdate.getFullYear() == year && thisdate.getMonth() == month && thisdate.getDate() == day && year.length == 4) {
            return true;
        }
        else {
            return false;
        }
    };
};

Sys.Mvc.ValidatorRegistry.validators.fieldlength = function (rule) {
    var minCharacters = rule.ValidationParameters.mincharacters;
    var message = rule.ErrorMessage;

    return function (value, context) {
        if (!value || !value.length) {
            return true; // return valid if value not specified - leave that to the 'required' validator
        }

        if ($.trim(value).length < minCharacters) {
            return false; // value too short - return invalid
        }
        else {
            return true;
        }
    };
};

// Checks for the valid post code regex if countries are of UK
Sys.Mvc.ValidatorRegistry.validators.validpostcode = function (rule) {
    var countryid = "";
    var message = rule.ErrorMessage;

    return function (value, context) {
        if (!value || !value.length) {
            return true; // return valid if value not specified - leave that to the 'required' validator
        }
        var thisField = context.fieldContext.elements[0];
        countryid = $(thisField).attr("id").replace(glPostCodeId, glCountryId).slice(0, -2);
        var countryObj = $('#' + countryid);
        countryObj.value = $("#" + countryid + " option: selected").val();
        //Post Code validation enable for countries (  UK - "1", Guernsey - "94" , Isle of Man - "110", Jersey - "115" ).
        if (countryObj.value == 1 || countryObj.value == 94 || countryObj.value == 110 || countryObj.value == 113 || countryObj.value === '' || countryObj.value === undefined) {
            var postCodeRegEx = new RegExp(rule.ValidationParameters.pattern);
            var result = postCodeRegEx.test(value);
            if (result !== true) {
                $('#btnContinue').css({ "opacity": "0.5" });
            }
            return result;
        }
        else {
            return true;
        }
    };
};

// Checks for the valid post code regex if countries are of UK
Sys.Mvc.ValidatorRegistry.validators.regularExpression = function (rule) {
    var message = rule.ErrorMessage;

    return function (value, context) {
        if (value != null && value.length > 0) {
            var postCodeRegEx = new RegExp(rule.ValidationParameters.pattern);
            return postCodeRegEx.test(value);
        }
        else {
            return true;
        }
    };
};

// Checks for the required post code regex if countries are of UK
Sys.Mvc.ValidatorRegistry.validators.postcoderequired = function (rule) {
    var countryid = "";
    var message = rule.ErrorMessage;

    return function (value, context) {
        var thisField = context.fieldContext.elements[0];
        countryid = $(thisField).attr("id").replace(glPostCodeId, glCountryId);
        var countryObj = $get(countryid, thisField.form);
        //Post Code validation enable for countries (  UK - "1", Guernsey - "94" , Isle of Man - "110", Jersey - "115" ).
        if (countryObj.value == 1 || countryObj.value == 94 || countryObj.value == 110 || countryObj.value == 113) {
            return value != null && value.length > 0
        }
        else {
            return true;
        }
    };
};

// Checks for the valid sort code regex
Sys.Mvc.ValidatorRegistry.validators.validsortcode = function (rule) {
    return function (value, context) {
        var sortcode = value + "";
        sortcode = sortcode.replace(/ /g, "").replace(/-/g, "");

        if (sortcode != null && sortcode.length > 0) {
            var regEx = new RegExp(rule.ValidationParameters.pattern);
            return regEx.test($.trim(value));
        }
        else {
            if (rule.ValidationParameters.requiredfielderrormessage != null && rule.ValidationParameters.requiredfielderrormessage.length > 0) {
                if (sortcode == null || sortcode.length == 0) {
                    return rule.ValidationParameters.requiredfielderrormessage;
                }
                else {
                    return true;
                }
            }
        }
    };
};